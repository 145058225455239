@import 'mixins';

*{
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}


html{
    scroll-behavior: smooth;
}

*{
    margin: 0px;
    padding: 0px;
    font-family: $robot;
}

.services__top{
    // background-color: red;
    // height: 100px;
    overflow: auto;
    flex-wrap: wrap;
}

.services__list{
    // background-color: yellow;
    padding: 0px;
    height: 100%;
    margin: 15px;
    @include flexRow(center);
}

.services__text{
    // background-color: pink;
    width: 100%;
    height: 100%;
    @include flexRow(center);
    word-wrap: none;
    width: 200px;
    word-break: none;

    &::before{
        // display: none;
    }
}

.main-section__input{
    width: 272px;
    // color: red;
}

.main-section__button{
    width: 272px;
    // color: red;
}

.services__text{
    cursor: pointer;
}

.services__block{
    display: none;
}

.services__block_active{
    @include flexColumn(flex-start);
}

.cat-price-block{
    display: block;
}

.cat{
    height: 500px !important;

    .showcase__img{
        // background-color: red;
        height: 430px;
    }

    .img-fluid{
        max-width: 80%;
        max-height: 80%;
    }
}

#modal-window{
    // display: block;
    @include flexRow(center);
    align-items: center;
    display: none;
}

.error-block{
    width: 100%;
    height: 90vh;
    // background-color: red;
    @include flexRow(center);
    align-items: center;
    text-align: center;

    &__container{
        @include flexColumn(center);
        // background-color: yellow;
        height: 100%;
    }

    &__message{
        font-size: 36px;
    }

    &__link{
        color: $main;
        font-size: 36px;
    }
}