@import 'vars';

@mixin flexRow($justify-content){
    display: flex;
    flex-direction: row;
    justify-content: $justify-content;
}

@mixin flexColumn($justify-content){
    display: flex;
    flex-direction: column;
    justify-content: $justify-content;
}